import { http } from "../../http";
import {
  ICreateSpotContract,
  ICreateSpotRequest,
  ICreateSpotResponse,
} from "./types";
import { AxiosResponse } from "axios";
import {
  IAxiosErrorResponse,
  ISpotErrorResponse,
  RepoSpotError,
} from "../error-response";

export class CreateSpotError extends RepoSpotError {}

export async function createSpot({
  outletId,
  name,
}: ICreateSpotRequest): Promise<ICreateSpotResponse> {
  let response: AxiosResponse<ICreateSpotContract>;

  try {
    response = await http.post(`/pat/locations/${outletId}/tables`, { name });
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    const response: IAxiosErrorResponse = e.response || {};
    const rawErrorMessage = response.data?.error?.message;

    const error: ISpotErrorResponse = {
      status: response.status,
      message: rawErrorMessage || "The request to create a spot has failed.",
      response: response.data,
    };

    const code = response.data?.error?.code;

    if (code === "table_with_same_name_already_exists_exception") {
      error.validationError = {
        name: response.data?.error?.message,
      };
    } else {
      error.validationError = {
        name: response.data?.errors?.name[0],
      };
    }

    throw new CreateSpotError(error);
  }

  return {
    id: response.data.id,
    locationId: response.data.location_id,
    name: response.data.name,
    posTableId: response.data.pos_table_id,
  };
}
