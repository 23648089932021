import { http } from "../../http";
import { IDeleteSpotRequest } from "./types";
import { IRepoErrors } from "@/v2/repo/errors";

export class DeleteSpotError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: DeleteSpot");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}
export async function deleteSpot({
  outletId,
  spotId,
}: IDeleteSpotRequest): Promise<boolean> {
  try {
    await http.delete(`/pat/locations/${outletId}/tables/${spotId}`);

    return true;
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new DeleteSpotError(error);
  }
}
