import { http } from "../../http";
import {
  IUpdateSpotContract,
  IUpdateSpotRequest,
  IUpdateSpotResponse,
  IUpdateSpotBodyParams,
} from "./types";
import { AxiosResponse } from "axios";
import {
  IAxiosErrorResponse,
  ISpotErrorResponse,
  RepoSpotError,
} from "../error-response";

export class UpdateSpotError extends RepoSpotError {}

export async function updateSpot(
  request: IUpdateSpotRequest
): Promise<IUpdateSpotResponse> {
  let response: AxiosResponse<IUpdateSpotContract>;

  try {
    const params: IUpdateSpotBodyParams = {
      name: request.name,
      seats: request.seats ?? null,
      grouping_label: request.section ?? null,
    };
    response = await http.put(
      `/pat/locations/${request.outletId}/tables/${request.tableId}`,
      {
        ...params,
      }
    );
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    const response: IAxiosErrorResponse = e.response || {};
    const rawErrorMessage = response.data?.error?.message;
    const code = response.data?.error?.code;

    const error: ISpotErrorResponse = {
      status: response.status,
      message:
        rawErrorMessage || "The request to create a location data failed.",
      response: response.data?.error?.context,
    };

    if (code === "table_with_same_name_already_exists") {
      error.validationError = {
        name: response.data?.error?.message,
      };
    } else {
      error.validationError = {
        name: response.data?.errors?.name[0],
      };
    }

    throw new UpdateSpotError(error);
  }

  return {
    id: response.data.id,
    locationId: response.data.location_id,
    name: response.data.name,
    posTableId: response.data.pos_table_id,
    section: response.data.grouping_label,
    seats: response.data.seats,
  };
}
