import { http } from "../../http";
import { IFetchSpotsRequest } from "./types";
import { AxiosResponse } from "axios";
import { IRepoErrors } from "@/v2/repo/errors";

export class FetchAllQrCodeZipError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchAllQrCodeZip");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function fetchAllQrCodeZip({
  outletId,
}: IFetchSpotsRequest): Promise<string> {
  let response: AxiosResponse;

  try {
    response = await http.get(
      `/pat/locations/${outletId}/tables/download-qr-codes`,
      {
        responseType: "arraybuffer",
      }
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchAllQrCodeZipError(error);
  }

  return response.data as string;
}
