
















































































































































































import { computed, defineComponent, ref, watch } from "@vue/composition-api";
import { t, tc } from "@/i18n";
import { bemBuilder } from "@/v2/util/bem-builder";
import {
  AtomButton,
  AtomText,
  AtomIcon,
  OrgHeaderInfo,
  OrgTable,
  MolTableAction,
  MolTableActionTypeEnum,
  MolMultiselect,
  AtomTextTypeEnum,
  MolModalConfirm,
  AtomTextTagEnum,
  AtomTextColorEnum,
  MolSearchBox,
  IOrgTableProps,
} from "@/v2/new-design-system";
import { report } from "@chatfood/bug-reporter";
import { useRouter } from "@/router";
import type { IFetchSpotsResponse } from "@/v2/repo/spots-management/fetch-spots/types";
import { fetchSpots } from "@/v2/repo/spots-management/fetch-spots";
import { deleteSpot } from "@/v2/repo/spots-management/delete-spot";
import { IOutlet, ITable } from "@/v2/module/spots-management/types";
import { fetchAllBusinessLocations } from "@/v2/repo/table-payment/fetch-all-business-locations";
import { CreateSpotModal } from "../create-spot-modal";
import { QrCodeModal } from "../qr-code-modal";
import { UpdateSpotModal } from "../update-spot-modal";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { Toast } from "@/design-system";
import { useDebounceValue } from "@/composable";
import { fetchAllQrCodeZip } from "@/v2/repo/spots-management";
import { downloadFile, FileTypeEnum } from "@/v2/util/download-file";
import { CreateBulkModal } from "../create-bulk-modal";

const css = bemBuilder("spots-management-main");

export default defineComponent({
  name: "SpotsManagementMain",
  components: {
    AtomText,
    AtomButton,
    AtomIcon,
    MolMultiselect,
    OrgHeaderInfo,
    CreateSpotModal,
    MolSearchBox,
    UpdateSpotModal,
    MolModalConfirm,
    MolTableAction,
    CreateBulkModal,
    OrgTable,
    QrCodeModal,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    outletId: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const isLoading = ref(false);
    const isLoadingQRCodeZipFile = ref(false);
    const showCreateSpotModal = ref(false);
    const showCreateBulkModal = ref(false);
    const showUpdateSpotModal = ref(false);

    const tableData = ref<IFetchSpotsResponse>([]);
    const tableSelected = ref<ITable>();
    const router = useRouter();

    const qrData = ref<{
      name: string;
      qrCodeUrl: string;
      shortUrl?: string;
    }>();

    const outlets = ref<IOutlet[]>([]);

    const outletSelected = ref<IOutlet>();

    async function downloadAllQRCodes() {
      if (!outletSelected.value?.id) return;
      isLoadingQRCodeZipFile.value = true;

      try {
        downloadFile(
          await fetchAllQrCodeZip({ outletId: outletSelected.value?.id }),
          FileTypeEnum.ZIP,
          `${outletSelected.value.name.toLowerCase().replaceAll(" ", "-")}.zip`
        );

        new Toast().create({
          type: "success",
          title: t("module.spots_management.main.download_started"),
        });
      } catch {
        new Toast().create({
          type: "error",
          title: t("module.spots_management.main.no_qr_codes_to_download"),
        });
      } finally {
        isLoadingQRCodeZipFile.value = false;
      }
    }

    watch(
      () => outletSelected.value?.id,
      () => (outletSelected.value ? getSpots() : getOutlets()),
      {
        immediate: true,
      }
    );

    const searchQuery = ref("");
    const { debouncedValue } = useDebounceValue(searchQuery, 500);
    const filteredSpots = computed(() => {
      return tableData.value.filter((line) => {
        return line.name
          .toLowerCase()
          .includes(debouncedValue.value.toLowerCase().trim());
      });
    });

    const tableColumns: IOrgTableProps["columns"] = {
      name: {
        header: () => t("module.spots_management.main.name_column"),
        show: true,
        sortable: true,
        width: 200,
        sortDirection: "ASC",
      },
      id: {
        header: () => t("module.spots_management.main.id_column"),
        show: true,
        sortable: false,
        width: 200,
        sortDirection: "NONE",
      },
      section: {
        header: () => t("module.spots_management.main.section_column"),
        show: true,
        sortable: false,
        width: 200,
        sortDirection: "NONE",
      },
      seats: {
        header: () => t("module.spots_management.main.seats_column"),
        show: true,
        sortable: false,
        width: 200,
        sortDirection: "NONE",
      },
      integrated: {
        header: () => t("module.spots_management.main.integrated_column"),
        show: true,
        sortable: true,
        width: 100,
        sortDirection: "NONE",
      },
    };

    async function getOutlets() {
      if (outlets.value.length > 0) return;

      try {
        const data = await fetchAllBusinessLocations(props.businessId);
        if (data.length) {
          outlets.value = data.map(({ id, name }) => ({
            id,
            name,
          }));

          if (props.outletId) {
            outletSelected.value = outlets.value.find(
              (outlet: IOutlet) => outlet.id === props.outletId
            );
            return;
          }
          updateOutletRoute(
            outletSelected.value ? outletSelected.value.id : outlets.value[0].id
          );
        }
      } catch (e: any) {
        const { error = {} } = e;
        report(e, {
          context: {
            error,
          },
          tags: {
            "http.response_code": error.status,
          },
        });
      }
    }

    async function getSpots(): Promise<void> {
      if (!outletSelected.value) return;

      isLoading.value = true;

      const outletId = props.outletId
        ? props.outletId
        : outletSelected.value.id;

      try {
        tableData.value = await fetchSpots({ outletId });
      } catch (e: any) {
        const { error = {} } = e;
        report(e, {
          context: {
            error,
          },
          tags: {
            "http.response_code": error.status,
          },
        });
      } finally {
        isLoading.value = false;
      }
    }

    function updateOutletRoute(outletId: string) {
      outletSelected.value = outlets.value.find(
        (outlet: IOutlet) => outlet.id === outletId
      );

      router.replace({
        name: "spots-management",
        params: {
          businessId: props.businessId,
          outletId,
        },
      });
    }

    function setOutlet(outlet: IOutlet) {
      if (outlet.id === props.outletId) return;
      updateOutletRoute(outlet.id);
    }

    function openEditSpotModal(table: ITable) {
      if (table.integrated) {
        new Toast().create({
          type: "error",
          text: t("module.spots_management.main.integrated_spot_message"),
        });
        return;
      }

      if (!outletSelected.value) return;
      tableSelected.value = {
        id: table.id,
        name: table.name,
        integrated: table.integrated,
        seats: table.seats,
        section: table.section,
      };
      showUpdateSpotModal.value = true;
    }

    function toggleCreateSpotModal() {
      showCreateSpotModal.value = !showCreateSpotModal.value;
    }

    function toggleCreateBulkModal() {
      showCreateBulkModal.value = !showCreateBulkModal.value;
    }

    function toggleUpdateSpotModal() {
      showUpdateSpotModal.value = !showUpdateSpotModal.value;
    }

    const showQrCodeModal = ref(false);

    function toggleDisplayQrCodeModal() {
      showQrCodeModal.value = !showQrCodeModal.value;
    }

    const displayQrCodeModal = (data: {
      name: string;
      qrCodeUrl: string;
      shortUrl?: string;
    }) => {
      qrData.value = data;
      showQrCodeModal.value = true;
    };

    async function copyShortUrl(url: string) {
      copyToClipboard(url, t("module.spots_management.main.copy_url_success"));
    }

    async function copyId(id: string) {
      copyToClipboard(id, t("module.spots_management.main.copy_id_success"));
    }

    async function copyToClipboard(value: string, message: string) {
      try {
        window.navigator.clipboard.writeText(value);

        new Toast().create({
          type: "success",
          title: message,
        });
      } catch {
        generalErrorToast();
      }
    }

    const deleteModal = ref(false);
    const deleteSpotData = ref({
      id: "",
      name: "",
    });

    const showDeleteModal = (menuId: string, menuName: string) => {
      deleteModal.value = true;
      deleteSpotData.value = {
        id: menuId,
        name: menuName,
      };
    };

    const askDeleteSpot = async (spotId: string) => {
      try {
        await deleteSpot({ outletId: props.outletId, spotId });
        tableData.value = tableData.value.filter((spot) => spot.id !== spotId);
      } catch (e) {
        report(e);
        generalErrorToast();
      } finally {
        deleteModal.value = false;
      }
    };

    return {
      t,
      tc,
      css,
      isLoading,
      copyId,
      copyShortUrl,
      outletSelected,
      tableSelected,
      showCreateSpotModal,
      showUpdateSpotModal,
      outlets,
      filteredSpots,
      searchQuery,
      tableData,
      tableColumns,
      MolTableActionTypeEnum,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomTextTagEnum,
      deleteModal,
      deleteSpotData,
      showDeleteModal,
      askDeleteSpot,
      setOutlet,
      toggleCreateSpotModal,
      toggleUpdateSpotModal,
      toggleCreateBulkModal,
      showCreateBulkModal,
      openEditSpotModal,
      downloadAllQRCodes,
      isLoadingQRCodeZipFile,
      getSpots,
      toggleDisplayQrCodeModal,
      displayQrCodeModal,
      showQrCodeModal,
      qrData,
    };
  },
});
