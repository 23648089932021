





































































import { t } from "@/i18n";
import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, ref, PropType, computed } from "@vue/composition-api";
import {
  AtomButton,
  AtomModal,
  AtomText,
  AtomTextColorEnum,
  AtomTextTypeEnum,
  AtomButtonTypeEnum,
  OrgFormInput,
} from "@/v2/new-design-system";
import { report } from "@chatfood/bug-reporter";
import { ICreateSpotResponse } from "@/v2/repo/spots-management/create-spot/types";
import { createSpot } from "@/v2/repo/spots-management/create-spot/create-spot";
import { IOutlet } from "../types";

const css = bemBuilder("create-spot-modal");

export default defineComponent({
  name: "CreateSpotModal",
  components: {
    AtomButton,
    AtomModal,
    AtomText,
    OrgFormInput,
  },
  props: {
    outlet: {
      type: Object as PropType<IOutlet>,
      required: true,
    },
    onClose: {
      type: Function as PropType<(e?: Event) => void>,
      required: true,
    },
    onSubmitSuccess: {
      type: Function as PropType<(e?: Event) => void>,
      required: true,
    },
  },
  setup(props) {
    const maxLengthMessage = 25;
    const entryName = ref("");
    const errorMessage = ref("");
    const isLoading = ref(false);

    const messageCounter = computed(() => {
      return t("module.spots_management.create_spot_modal.x_characters", {
        length: entryName.value.length,
        max: maxLengthMessage,
      });
    });

    const submitCreateSpot = async () => {
      isLoading.value = true;

      let response: ICreateSpotResponse | undefined;

      try {
        response = await createSpot({
          outletId: props.outlet.id,
          name: entryName.value,
        });
      } catch (e: any) {
        const { error = {} } = e;

        if (error.validationError && error.validationError.name) {
          errorMessage.value = error.validationError.name;
        } else {
          report(e.message, {
            context: {
              error: e,
            },
            tags: {
              "http.response_code": e.statusCode,
            },
          });
        }
      } finally {
        isLoading.value = false;
      }

      if (!response) return;

      props.onClose();
      props.onSubmitSuccess();
    };

    const onChange = (value: string) => (entryName.value = value.trim());

    return {
      t,
      css,
      entryName,
      isLoading,
      errorMessage,
      AtomTextColorEnum,
      AtomTextTypeEnum,
      AtomButtonTypeEnum,
      maxLengthMessage,
      messageCounter,
      submitCreateSpot,
      onChange,
    };
  },
});
