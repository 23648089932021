import { http } from "../../http";
import {
  IFetchLocationContract,
  IFetchLocationRequest,
  IFetchLocationResponse,
} from "./types";
import { AxiosResponse } from "axios";
import { IRepoErrors } from "@/v2/repo/errors";

export class FetchLocationError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchLocation");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}
export async function fetchLocation({
  outletId,
}: IFetchLocationRequest): Promise<IFetchLocationResponse> {
  let response: AxiosResponse<IFetchLocationContract>;

  try {
    response = await http.get(`/pat/locations/${outletId}`);
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchLocationError(error);
  }

  return {
    id: response.data.id,
    name: response.data.name,
  };
}
