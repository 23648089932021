



















































































import { t } from "@/i18n";
import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, ref, PropType, computed } from "@vue/composition-api";
import {
  AtomButton,
  AtomModal,
  AtomText,
  AtomTextColorEnum,
  AtomTextTypeEnum,
  AtomButtonTypeEnum,
  OrgFormInput,
} from "@/v2/new-design-system";
import { report } from "@chatfood/bug-reporter";
import { IUpdateSpotResponse } from "@/v2/repo/spots-management/update-spot/types";
import { updateSpot } from "@/v2/repo/spots-management/update-spot/update-spot";
import { IOutlet, ITable } from "../types";
const css = bemBuilder("create-spot-modal");
export default defineComponent({
  name: "UpdateSpotModal",
  components: {
    AtomButton,
    AtomModal,
    AtomText,
    OrgFormInput,
  },
  props: {
    outlet: {
      type: Object as PropType<IOutlet>,
      required: true,
    },
    table: {
      type: Object as PropType<ITable>,
      required: true,
    },
    onClose: {
      type: Function as PropType<(e?: Event) => void>,
      required: true,
    },
    onSubmitSuccess: {
      type: Function as PropType<(e?: Event) => void>,
      required: true,
    },
  },
  setup(props) {
    const maxLengthMessage = 25;
    const entryName = ref<string>(props.table.name);
    const entrySeats = ref<string>(props.table.seats?.toString() ?? "");
    const entrySection = ref<string>(props.table.section ?? "");
    const errorMessage = ref("");
    const isLoading = ref(false);

    const messageCounter = computed(() => {
      return t("module.spots_management.update_spot_modal.x_characters", {
        length: entryName.value.length,
        max: maxLengthMessage,
      });
    });

    const submitCreateSpot = async () => {
      isLoading.value = true;
      let response: IUpdateSpotResponse | undefined;

      try {
        response = await updateSpot({
          outletId: props.outlet.id,
          tableId: props.table.id,
          name: entryName.value,
          seats: entrySeats.value.length
            ? parseInt(entrySeats.value, 10)
            : undefined,
          section: entrySection.value,
        });
      } catch (e: any) {
        const { error = {} } = e;
        if (error.validationError && error.validationError.name) {
          errorMessage.value = error.validationError.name;
        } else {
          report(e.message, {
            context: {
              error: e,
            },
            tags: {
              "http.response_code": e.statusCode,
            },
          });
        }
      } finally {
        isLoading.value = false;
      }

      if (!response) return;

      props.onClose();
      props.onSubmitSuccess();
    };

    return {
      t,
      css,
      entryName,
      isLoading,
      errorMessage,
      AtomTextColorEnum,
      AtomTextTypeEnum,
      AtomButtonTypeEnum,
      maxLengthMessage,
      messageCounter,
      submitCreateSpot,
      entrySeats,
      entrySection,
    };
  },
});
