import { http } from "../../http";
import { ICreateSpotRequest, ICreateSpotResponse } from "./types";

export class CreateSpotBulkError extends Error {}

export async function createSpotBulk({
  outletId,
  spotNames,
}: ICreateSpotRequest): Promise<ICreateSpotResponse> {
  try {
    await http.post(`/pat/create-tables-for-location/${outletId}`, {
      names: spotNames,
    });
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new CreateSpotBulkError(e);
  }

  return true;
}
