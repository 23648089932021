






















































import { t } from "@/i18n";
import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, ref, PropType } from "@vue/composition-api";
import {
  AtomButton,
  AtomModal,
  AtomText,
  AtomTextColorEnum,
  AtomTextTypeEnum,
  AtomButtonTypeEnum,
  OrgFormTextArea,
} from "@/v2/new-design-system";
import { report } from "@chatfood/bug-reporter";
import { IOutlet } from "../types";
import { createSpotBulk } from "@/v2/repo/spots-management";
import { Toast } from "@/design-system";

const css = bemBuilder("create-spot-modal");

export default defineComponent({
  name: "CreateBulkModal",
  components: {
    AtomButton,
    AtomModal,
    AtomText,
    OrgFormTextArea,
  },
  props: {
    outlet: {
      type: Object as PropType<IOutlet>,
      required: true,
    },
    onClose: {
      type: Function as PropType<(e?: Event) => void>,
      required: true,
    },
  },
  setup(props) {
    const maxLengthMessage = 25;
    const errorMessage = ref("");
    const formInputText = ref("");

    const submit = async () => {
      try {
        const spotDataToSave = prepareSpotData(formInputText.value);
        if (spotDataToSave.length > 400) {
          errorMessage.value = t(
            "module.spots_management.create_bulk_modal.entry_limit_error"
          );
          return;
        }

        if (spotDataToSave.some((spot) => spot.length > maxLengthMessage)) {
          errorMessage.value = t(
            "module.spots_management.create_bulk_modal.entry_char_limit_error",
            { count: maxLengthMessage }
          );
          return;
        }

        await createSpotBulk({
          outletId: props.outlet.id,
          spotNames: spotDataToSave,
        });

        new Toast().create({
          type: "success",
          title: t("module.spots_management.create_bulk_modal.success_toast"),
        });
      } catch (e: any) {
        new Toast().create({
          type: "error",
          title: t("module.spots_management.create_bulk_modal.error_toast"),
        });

        report(e.message, {
          context: {
            error: e,
          },
          tags: {
            "http.response_code": e.statusCode,
          },
        });
      }

      props.onClose();
    };

    function onInput(textInput: string) {
      formInputText.value = textInput.trim();
      errorMessage.value = "";
    }

    function prepareSpotData(input: string) {
      return input
        .split(/\r?\n/)
        .map((line) => line.trim())
        .filter((line) => Boolean(line));
    }

    return {
      t,
      css,
      errorMessage,
      AtomTextColorEnum,
      AtomTextTypeEnum,
      AtomButtonTypeEnum,
      maxLengthMessage,
      submit,
      formInputText,
      onInput,
    };
  },
});
