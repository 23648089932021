import { AxiosResponse } from "axios";
import { IRepoErrors } from "../../errors";
import { http } from "../../http";

interface Location {
  id: string;
  name: string;
}

export type IFetchAllBusinessLocationsResponse = Array<Location>;

export class FetchAllBusinessLocationsError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchAllBusinessLocations");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function fetchAllBusinessLocations(
  businessId: string
): Promise<IFetchAllBusinessLocationsResponse> {
  let response: AxiosResponse;

  try {
    response = await http.get(`/pat/businesses/${businessId}/locations`);
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchAllBusinessLocationsError(error);
  }

  return response?.data || [];
}
