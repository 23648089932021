

























import { t } from "@/i18n";
import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, PropType } from "@vue/composition-api";
import {
  AtomModal,
  AtomText,
  AtomTextTypeEnum,
  AtomTextTagEnum,
  AtomLink,
} from "@/v2/new-design-system";

const css = bemBuilder("qr-code-modal");

export default defineComponent({
  name: "QrCodeModal",
  components: {
    AtomModal,
    AtomText,
    AtomLink,
  },
  props: {
    onClose: {
      type: Function as PropType<(e?: Event) => void>,
      required: true,
    },
    title: {
      required: true,
      type: String,
    },
    qrCodeUrl: {
      required: true,
      type: String,
    },
    shortUrl: {
      default: undefined,
      type: String,
    },
  },
  setup() {
    return {
      t,
      css,
      AtomTextTypeEnum,
      AtomTextTagEnum,
    };
  },
});
